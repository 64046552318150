import React from 'react'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

import forBizHeroImg from '../images/hero-woman-selfie.jpg'
import forBizSvg from '../images/icon-gallery.svg'
import iconInstagramCircle from '../images/icon-instagram-circle.svg'
import iconTwitterCircle from '../images/icon-twitter-circle.svg'
import iconFacebookCircle from '../images/icon-facebook-circle.svg'

const Toolkit = () => (
  <Layout>
    <PageHelmet
      title="Gallery"
      description="Hashtag your Mask of Wellness posts to get your business displayed here."
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Social'}
      accentedTitle={'Gallery'}
      subtitle={'Coming soon'}
    />

    <section className="section" key="mow-forbiz-toolkit">
      <div className="container has-text-centred">
        <div className="columns">
          <div className="column">
            <p>
              We're actively developing a social gallery. Hashtag your Mask of
              Wellness posts to get your business displayed here.
            </p>
            <div className="content">
              <h3 className="title">Posts to Share</h3>
              <p>
                "We're proud to join #themaskofwellness community! Check out our
                website to learn how we're changing to stay open responsibly
                during #COVID19."
              </p>
              <p>
                "We're taking a positive approach promoting responsible behavior
                necessary to keep society open during #COVID19 while benefiting
                social, physical, and economic wellness."
              </p>
              <h3 className="title">Follow Us</h3>
              <a
                href="https://www.instagram.com/covidconsciousbusinesses/"
                target="_blank"
                className="footer-social-icon"
              >
                <img src={iconInstagramCircle} />
              </a>
              <a
                href="https://twitter.com/covidconscious"
                target="_blank"
                className="footer-social-icon"
              >
                <img src={iconTwitterCircle} />
              </a>
              <a
                href="https://www.facebook.com/covidconsciousbusinesses"
                target="_blank"
                className="footer-social-icon"
              >
                <img src={iconFacebookCircle} />
              </a>
              <p>
                <span className="accent">#</span>themaskofwellness
                <br />
                <span className="accent">#</span>covidconsciousbusiness
                <br />
                <span className="accent">#</span>covid-consciousbusiness
                <br />
                <span className="accent">#</span>maskofwellnessbusiness
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Toolkit
